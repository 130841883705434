export default {
    zh: {
        'home': '首页',
        'projectOverview': '项目概述',
        'accessDownload': '信标服务',
        'safetyAnalysis': '安全与分析',
        'about': '关于',
        'news': '新闻资讯',
        'login': '登录',
        'register': '注册',
        'member': '会员中心',
        'logout': '退出登录',
        "application": "应用服务",
        "downloadService": "下载服务",
    },
    en: {
        'home': 'Homepage',
        'projectOverview': 'Overview',
        'accessDownload': 'Beacon Service',
        'safetyAnalysis': 'Security',
        'about': 'About',
        'news': 'News',
        'login': 'Login',
        'register': 'Register',
        'member': 'Member',
        'logout': 'Logout',
        "application": "Application",
        "downloadService": "Download Service",
    }
}
